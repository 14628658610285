import cn from "classnames";
import React from "react";
import { pluralize } from "../../../common/utils";
import BaseComponent from "../../BaseComponent";
import { VersionsModal } from "./VersionsModal";

interface VersionTagProps {
  className?: string
  noSuffix?: boolean
  model: any
  vKey: string
  mode?: "simple" | "visualEditor"
}

export class VersionTag extends BaseComponent<VersionTagProps> {
  state = {
    modalOpen: ""
  };

  shouldComponentUpdate(nextProps: any, nextState: any): any {
    return true;
  }

  open = () => {
    this.setState({ modalOpen: true });
  };

  render() {
    const { model, vKey, noSuffix, className = "ml-8", mode = "simple" } = this.props;
    let version: any = 1;
    if (model && vKey) {
      // @ts-expect-error
      version = model[vKey + "_versions"];
    }

    if (!version || version < 1) {
      return null;
    }

    if (noSuffix) {
      version = `(v${version})`;
    }
    return (
      <>
        {this.state.modalOpen && (
          <VersionsModal
            model={model.gid}
            vKey={vKey}
            onClose={() => this.setState({ modalOpen: false })}
            mode={mode}
          />
        )}
        <ruby>
          <a className={cn(className, "versions link")} onClick={this.open} target="_parent">
            {version}
            {!noSuffix && " " + pluralize("version", version)}
          </a>
        </ruby>
      </>
    );
  }
}
