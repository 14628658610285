import React from "react";
import { friendlyDateTime } from "../../../admin/utils";

export const SimpleVersion = ({ version }) => {
  return (
    <div className={"version"} key={version.created_at}>
      <div className={"author"}>{version.user.full_name}</div>
      <div className={"timestamp"}>{friendlyDateTime(version.created_at)}</div>
      <ul className={"diffy-split-view"}>
        <li className={"previous"} dangerouslySetInnerHTML={{ __html: version.comparison.left }} />
        <li className={"current"} dangerouslySetInnerHTML={{ __html: version.comparison.right }} />
      </ul>
    </div>
  );
};
