import equal from "fast-deep-equal";
import ActionCableBase from "./ActionCableBase";
import Queue from "@/lib/Queue";
import { isEqual } from "lodash";

const readQueue = new Queue(500);
const consumedIds = new Queue(500);

window.__debug = { readQueue, consumedIds };
let LAST_CONSUMED: number = 0;

function splitOnce(s, on) {
  const [first, ...rest] = s.split(on);
  return [first, rest.length > 0 ? rest.join(on) : null];
}

export default class SubscriptionWorker extends ActionCableBase {
  protected channel_name = "GraphqlChannel";

  constructor(streamName: string) {
    super();
    this.connect({ stream_name: streamName });
  }

  handleMessage(id, message) {
    const item = message[id];
    if (item.__message_id) {
      if (consumedIds.has(item.__message_id)) {
        console.log("Already consumed", item.__message_id, item.__time, LAST_CONSUMED);
        return;
      }
      consumedIds.push(item.__message_id);
    }
    if (item.__delete) { return this.deleteObject(id, item); }

    if (this.inCache(id)) {
      this.updateObject(id, item);
      if (item.__parent) {
        this.ensureInParent(id, item, item.__parent);
      }
      if (this.isTypeOfRoot(id)) {
        this.ensureInParent(id, item, [this.getRootParent(id, item)]);
      }
      return;
    }
    if (item.__parent) { this.insertObject(id, item, item.__parent); }
    if (this.isTypeOfRoot(id)) {
      const parent = this.getRootParent(id, item);
      return this.insertObject(id, item, [parent]);
    }
  }

  publishUpdate(key: string, message: any = null) {
    console.log("Publishing to", key);
    PubSub.publish(key, message);
  }

  processMessages() {
    let message;
    // eslint-disable-next-line no-cond-assign
    while (message = readQueue.consume()) {
      for (const id in message) {
        if (id.startsWith("__")) { continue; }
        if (message.__time < LAST_CONSUMED) {
          console.error("Message read out of sync");
        }
        this.handleMessage(id, message);
        LAST_CONSUMED = message.__time;
        const _id = this.id(id);
        const key = `update.${_id.model}.${_id.id}`;
        this.publishUpdate(key, message[id]);
      }
    }
  }

  receiveMessage(message: any) {
    readQueue.push(message).sort("__time");
    this.processMessages();
  }

  private removeFromRoots(id, obj) {
    // Delete a newly modified object from ROOT_QUERYs it no longer matches. It will be re-added on the next step.
    const modelPlural = this.id(id).model_plural;
    // @ts-expect-error: private object
    const possibleRoots = Object.keys(window.apolloCache.data.data.ROOT_QUERY).filter(x => x.startsWith(modelPlural));
    for (const possibleRoot of possibleRoots) {
      if (!this.matchesRoot(possibleRoot, obj)) {
        // @ts-expect-error: private object
        const index = window.apolloCache.data.data.ROOT_QUERY[possibleRoot].findIndex(x => x.__ref == id);
        if (index > -1) {
          // @ts-expect-error: private object
          const array = [...window.apolloCache.data.data.ROOT_QUERY[possibleRoot]];
          array.splice(index, 1);
          window.apolloCache.modify({ id: "ROOT_QUERY", fields: { [possibleRoot]: () => array } });
        }
      }
    }
  }

  private updateObject(id, item) {
    console.info("Updating object", id, item);
    const fields = {};
    // @ts-expect-error: private object
    const cachedObject = window.apolloCache.data.data[id];

    for (const field in item) {
      if (field.startsWith("__")) { continue; }
      if (!equal(item[field], cachedObject[field])) {
        fields[field] = function() {
          if (item[field] && cachedObject[field]?.__typename) {
            item[field].__typename = cachedObject[field].__typename;
          }
          return item[field];
        };
      }
    }
    if (import.meta.env.MODE === "development") {
      this.objectCompareTool(cachedObject, item, true);
    }
    window.apolloCache.modify({ id, fields });
    // @ts-expect-error: private object
    this.removeFromRoots(id, window.apolloCache.data.data[id]);
  }

  private ensureInParent(id, message, parents) {
    if (!Array.isArray(parents)) { parents = parents.split(","); }
    for (const parent of parents) {
      const [parentId, field] = parent.split(".");
      const fields = {};
      fields[field] = (existingRefs) => {
        const newRef = { __ref: id };
        if (existingRefs.some(ref => ref.__ref == id)) {
          return existingRefs;
        }
        if (import.meta.env.MODE === "development") {
          if (existingRefs.length) {
            // @ts-expect-error: private object
            const otherObj = window.apolloCache.data.data[existingRefs[0].__ref];
            this.objectCompareTool(otherObj, message);
          }
        }

        this.addToCache(id, message);
        return [...existingRefs, newRef];
      };
      window.apolloCache.modify({ id: parentId, fields });
      setTimeout(() => {
        const _id = this.id(parentId);
        const key = `update.${_id.model}.${_id.id}`;
        this.publishUpdate(key);
      }, 0);
    }
  }

  private objectCompareTool(oldObj, newObj, typesOnly = false) {
    const [oldKeys, newKeys] = [oldObj, newObj].map(Object.keys).map(x => x.sort());
    if (!typesOnly) {
      if (oldKeys.length !== newKeys.length) {
        console.warn("Object keys are not the same length");
        for (const key of oldKeys) {
          if (!newKeys.includes(key)) {
            console.warn("New obj is missing key", key);
          }
        }
      }
    }
    for (const key of oldKeys) {
      if (typeof oldObj[key] !== typeof newObj[key]) {
        if (oldObj[key] == undefined || newObj[key] == undefined) { continue; }
        console.warn(`Object ${key} is of type ${typeof oldObj[key]}  in the oldObj
          but in the new one it is ${typeof newObj[key]}`);
      }
    }
  }

  private insertObject(id, message, parent) {
    console.info("Inserting object", id, message);
    this.ensureInParent(id, message, parent);
  }

  getParentsOfType(id) {
    const modelPlural = this.id(id).model_plural;
    // @ts-expect-error: private object
    return Object.keys(window.apolloCache.data.data.ROOT_QUERY)
      .filter(key => key == modelPlural || key.startsWith(modelPlural + "("))
      .map(key => `ROOT_QUERY.${key}`);
  }

  private deleteObject(id, message) {
    console.info("Deleting object", id, message);
    let parents = message.__parent;
    if (message.__parent) {
      if (!Array.isArray(parents)) { parents = parents.split(","); }
    }
    if (this.isTypeOfRoot(id)) {
      parents = this.getParentsOfType(id);
    }
    if (!parents?.length) {
      return console.error("Cannot find object to delete", id, message);
    }
    for (const parent of parents) {
      const [parentId, field] = splitOnce(parent, ".");
      const fields = {};
      fields[field] = function(existingRefs) {
        return existingRefs.filter(ref => ref.__ref !== id);
      };
      window.apolloCache.modify({ id: parentId, fields });
      setTimeout(() => {
        const _id = this.id(parentId);
        const key = `update.${_id.model}.${_id.id}`;
        this.publishUpdate(key);
      });
    }
  }

  private pluralize(modelName): string {
    const snailCase = x => x.replace(/([A-Z])/g, l => " " + l)
      .trim()
      .toLowerCase()
      .replace(/ /g, "_");
    return snailCase(modelName) + "s";
  }

  private id(fullId: string) {
    const [model, id] = fullId.split(":");
    return { model, id, model_plural: this.pluralize(model) };
  }

  private addToCache(id: string, item: any) {
    item.__typename = this.id(id).model;
    item.id = item.id + "";
    delete item.__parent;
    // @ts-expect-error: private object
    window.apolloCache.data.data[id] = item;
  }

  private inCache(id: string) {
    // @ts-expect-error: private object
    return !!window.apolloCache.data.data[id];
  }

  private isTypeOfRoot(id: string) {
    const key = this.getRootParent(id)?.split(".")[1];
    if (!key) { return false; }
    // @ts-expect-error: private object
    return !!window.apolloCache.data.data.ROOT_QUERY[key];
  }

  private getRootParent(id, message = null as any | null) {
    const modelPlural = this.id(id).model_plural;

    // @ts-expect-error: private object
    const possibleKeys = Object.keys(window.apolloCache.data.data.ROOT_QUERY)
      .filter(key => key == modelPlural || key.startsWith(modelPlural + "("));
    if (possibleKeys.length === 0) { return null; }
    let returnKey: string = possibleKeys[0];
    if (message) {
      returnKey = this.getRootKeyForObject(possibleKeys, message) ?? "";
    }
    return "ROOT_QUERY." + returnKey;
  }

  private matchesRoot(key, message): boolean {
    const match = key.match(/\w+\((.*)\)/);
    if (match == null) { return true; } // The query has no filters, so catch-all list.

    const [, hash] = match;
    const filter = JSON.parse(hash);
    const filterKeys = Object.keys(filter)
      .filter(x => !x.toLowerCase().endsWith("id"));

    if (filterKeys.length === 0) { return key; }

    return filterKeys.every((k) => {
      const filterClause = filter[k];
      if (filterClause == null) return true;

      if (Array.isArray(filterClause)) {
        return filterClause == message[k] || filterClause.includes(message[k]);
      }

      return isEqual(filterClause, message[k]);
    });
  }

  private getRootKeyForObject(possibleKeys, message): string | null {
    for (const key of possibleKeys) {
      if (this.matchesRoot(key, message)) { return key; }
    }
    return null;
  }
}
