import { VersionsQuery } from "gql/versions.graphql";
import React from "react";
import { Modal } from "../../../common/components";
import { gqlQuery } from "../../../common/lib";
import BaseComponent from "../../BaseComponent";
import { SimpleVersion } from "./SimpleVersion";
import { VisualEditorVersion } from "./VisualEditorVersion";

export class VersionsModal extends BaseComponent<{ model: string, vKey: string, onClose: () => void, mode?: string }> {
  handleClose = () => {
    this.props.onClose();
  };

  get VersionComponent() {
    if (this.props.mode === "visualEditor") {
      return VisualEditorVersion;
    } else {
      return SimpleVersion;
    }
  }

  render() {
    const { model, vKey, mode } = this.props;

    return (
      <Modal open={true} title={"Versions"} onClose={this.handleClose}>
        {gqlQuery(
          VersionsQuery,
          { model, vKey, mode },
          (res) => (
            <div className={"diff-modal-inside"}>
              <h3>{res.versions.object_name}</h3>
              {res.versions.versions.map((version) => (
                <this.VersionComponent version={version} />
              ))}
            </div>
          ),
          { fetchPolicy: "network-only" }
        )}
      </Modal>
    );
  }
}
