import React from "react";
import { friendlyDateTime } from "../../../admin/utils";

export const VisualEditorVersion = ({ version }) => {
  return (
    <div className={"version"} key={version.created_at}>
      <div className={"author"}>{version.user.full_name}</div>
      <div className={"timestamp"}>{friendlyDateTime(version.created_at)}</div>
      <div className={"diffy-split-html-view fr-view"}>
        <div className={"previous w-100"} dangerouslySetInnerHTML={{ __html: version.comparison.left }} />
        <div className={"current w-100"} dangerouslySetInnerHTML={{ __html: version.comparison.right }} />
      </div>
    </div>
  );
};
