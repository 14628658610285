import { GQLReport, GQLReportIndicator, GQLTier } from "@/schemas/schema";
import { EmphasisReport, getReportType } from "./reportTypes";
import { CheckboxStatus, EmphasisTiers, IndicatorVisibility } from "@/common/constants";

export const getReportIndicatorType = (report: GQLReport, reportIndicator: GQLReportIndicator): ReportIndicatorType => {
  if (getReportType(report) instanceof EmphasisReport) {
    return reportIndicator.emphasis_tier?.id == EmphasisTiers.essential ? new EssentialReportIndicator() : new AdditionalReportindicator();
  } else {
    return new LegacyReportIndicator();
  }
};

export interface ReportIndicatorType {
  resolveCheckboxStatus: (val: boolean, reportIndicator: GQLReportIndicator) => number
  tier: (reportIndicator: GQLReportIndicator) => GQLTier
  resolveSelectorChecked: (reportIndicator: GQLReportIndicator) => boolean
}

export class LegacyReportIndicator implements ReportIndicatorType {
  resolveCheckboxStatus(val: boolean, reportIndicator: GQLReportIndicator): number {
    return reportIndicator.is_visible === val ? CheckboxStatus.noChange : CheckboxStatus.change;
  }

  tier(reportIndicator: GQLReportIndicator): GQLTier {
    return reportIndicator.tier!;
  }

  resolveSelectorChecked(reportIndicator: GQLReportIndicator): boolean {
    return reportIndicator.is_visible!;
  }
}

export class EssentialReportIndicator extends LegacyReportIndicator {
  resolveCheckboxStatus(val: boolean, reportIndicator: GQLReportIndicator): number {
    if ((val && reportIndicator.visible == IndicatorVisibility.unscored) || (!val && reportIndicator.visible == IndicatorVisibility.scored)) {
      return CheckboxStatus.change;
    } else {
      return CheckboxStatus.noChange;
    }
  }

  tier(reportIndicator: GQLReportIndicator): GQLTier {
    return reportIndicator.emphasis_tier!;
  }

  resolveSelectorChecked(reportIndicator: GQLReportIndicator): boolean {
    return reportIndicator.visible === IndicatorVisibility.scored;
  }
}

export class AdditionalReportindicator extends EssentialReportIndicator {
  resolveCheckboxStatus(val: boolean, reportIndicator: GQLReportIndicator): number {
    if ((val && reportIndicator.disabled) || (!val && !reportIndicator.disabled)) {
      return CheckboxStatus.change;
    } else {
      return CheckboxStatus.noChange;
    }
  }

  resolveSelectorChecked(reportIndicator: GQLReportIndicator): boolean {
    return !reportIndicator.disabled;
  }
}
