import { GQLReport, GQLReportTrack } from "@/schemas/schema";

export const getReportType = (report: GQLReport): ReportType => {
  // all new reports are emphasis reports by default
  if (report?.emphasis_indicators === false) {
    return new NonEmphasisReport();
  } else {
    return new EmphasisReport();
  }
};

export interface ReportType {
  trackSelectorTierColumn: () => any
  trackSelectorTierHeader: () => string
  trackSelectorProgressColumn: () => any
  trackSelectorProgressItems: (reportTrack: GQLReportTrack) => any
  renderEssentialIndicatorsFilter: () => boolean
  calculateScoredIndicatorsPercent: (report: GQLReport) => any
}

export class NonEmphasisReport implements ReportType {
  trackSelectorTierColumn() {
    return {
      key: "tier",
      name: "Tier",
      fieldName: "trackTier"
    };
  }

  trackSelectorTierHeader() {
    return "Tier";
  }

  trackSelectorProgressColumn(): any {
    return [{ key: "progress", name: "Progress", minWidth: 60, fieldName: "progress" }];
  }

  trackSelectorProgressItems(reportTrack: GQLReportTrack): any {
    return {
      progress: `${reportTrack.indicators_scored!}/${reportTrack.indicators_visible!}`
    };
  }

  renderEssentialIndicatorsFilter() {
    return false;
  }

  calculateScoredIndicatorsPercent(report: GQLReport): any {
    const indicatorsScored = report.report_tracks!.map((x) => x.indicators_scored!).reduce((s, i) => s + i, 0);
    const indicatorsVisible = report.report_tracks!.map((x) => x.indicators_visible!).reduce((s, i) => s + i, 0);
    const percentComplete = Math.round((indicatorsScored / indicatorsVisible) * 100);
    const indicatorScoreBarText = `Indicators Scored ${percentComplete}%`;

    return { percentComplete, indicatorScoreBarText };
  }
}

export class EmphasisReport extends NonEmphasisReport {
  trackSelectorTierColumn(): any {
    return {
      key: "tier",
      name: "Emphasis",
      fieldName: "trackTier"
    };
  }

  trackSelectorTierHeader() {
    return "Emphasis";
  }

  trackSelectorProgressColumn() {
    return [
      { key: "essential_progress", name: "Essential Progress", fieldName: "essential_progress" },
      { key: "additional_progress", name: "Additional Progress", fieldName: "additional_progress" }
    ];
  }

  trackSelectorProgressItems(reportTrack: GQLReportTrack): any {
    return {
      essential_progress: `${reportTrack.essential_indicators_scored_count!}/${reportTrack.essential_indicators_count!}`,
      additional_progress: `${reportTrack.additional_indicators_scored_count!}/${reportTrack.additional_indicators_count!}`
    };
  }

  renderEssentialIndicatorsFilter() {
    return true;
  }

  calculateScoredIndicatorsPercent(report: GQLReport): any {
    const essentialIndicatorsScored = report.report_tracks!.map((rt) => rt.essential_indicators_scored_count!).reduce((s, i) => s + i, 0);
    const essentialIndicatorsForScoringCount = report.report_tracks!.map((rt) => rt.essential_indicators_for_scoring_count!).reduce((s, i) => s + i, 0);
    const percentComplete = essentialIndicatorsForScoringCount == 0 ? 100 : Math.round((essentialIndicatorsScored / essentialIndicatorsForScoringCount) * 100);
    const indicatorScoreBarText = `Essential Indicators Scored ${percentComplete}%`;

    return { percentComplete, indicatorScoreBarText };
  }
}
