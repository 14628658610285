import { IReportElement, Modal, ReportElementsTab, transformReportElements } from "@/common/components";
import { blockPage, gqlMutation, unblockPage } from "@/common/lib";
import { deep_clone, sortOrderBasedOnKeysArray } from "@/common/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gql from "graphql-tag";
import { Checkbox, DefaultButton, Pivot, PivotItem, PrimaryButton } from "office-ui-fabric-react";
import React from "react";
import { Container } from "react-grid-system";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import { flatten } from "../../../admin/utils";
import { GQLReport, GQLReportIndicator, GQLReportTrack, GQLTopic } from "../../../schemas/schema";
import BaseComponent from "../../BaseComponent";
import { SelectedReportElements } from "../../common";
import { TopicContainer } from "./TopicContainer";
import { DefaultResetReportTrackReportElements } from "gql/mutations/report_track.graphql";
import { CheckboxStatus, EmphasisTiers, IndicatorVisibility } from "@/common/constants";
import { getReportType } from "@/lib/reportTypes";
import { getReportIndicatorType } from "@/lib/reportIndicatorTypes";

interface Props {
  rt: GQLReportTrack
  onClose: () => void
  report: GQLReport
}

const DragHandle = SortableHandle(() => (
  <div className="drag-handle">
    <FontAwesomeIcon icon={"grip-vertical"} />
  </div>
));

const TopicElement = SortableElement(({ value }) => (
  <li style={{ zIndex: 10000002 }}>
    <DragHandle />
    {value}
  </li>
));

export class IndicatorSelectorModalInner extends BaseComponent<Props> {
  mutation = gql`
    mutation update_visible_indicators($indicatorValues: JSON!, $sortOrders: JSON!) {
      set_visible_indicators(indicator_values: $indicatorValues, sort_order: $sortOrders) {
        report_indicators {
          id
          is_visible
        }
        report_tracks {
          id
          indicators_scored
          indicators_visible
          report_elements
        }
      }
    }
  `;

  resetSortMutation = gql`
    mutation reset_sort {
      reset_rt_sort(report_track: ${this.props.rt.id}) {
        success
      }
    }
  `;

  updateReportElements = gql`
    mutation report_track($id: ID, $report_element: JSON!) {
      update_report_track_report_element(id: $id, report_element: $report_element) {
        gid
        id
        report_elements
      }
    }
  `;

  defaultResetReportElements = DefaultResetReportTrackReportElements;

  state = {
    values: {},
    topics: [] as GQLTopic[],
    selectedReportElements: new Set<String>()
  };

  private orderAltered = false;

  constructor(props) {
    super(props);
    this.state = {
      values: {},
      topics: deep_clone(props.rt.topics)
        .map((topic) => ({
          ...topic,
          report_indicators: topic.report_indicators.sort((a, b) => a.sort_order - b.sort_order)
        }))
        .sort((a, b) => a.sort_order - b.sort_order),
      selectedReportElements: new Set<String>(
        transformReportElements(this.props.rt)
          .filter((e) => e.selected)
          .map((e) => e.name)
      )
    };
  }

  setSelectedReportElements(newValues: Set<String>) {
    this.setState({ selectedReportElements: newValues });
  }

  renderIndicators() {
    return this.state.topics.map((topic) => this.renderTopic(topic, topic.report_indicators));
  }

  shouldComponentUpdate() {
    return true;
  }

  onSortEnd = ({ oldIndex, newIndex }, topic: GQLTopic) => {
    const { topics } = this.state;
    const topicIndex = topics.map((x) => x.gid).indexOf(topic.gid);
    const i = topic.report_indicators![oldIndex];
    topics[topicIndex].report_indicators!.splice(oldIndex, 1);
    topics[topicIndex].report_indicators!.splice(newIndex, 0, i);
    this.orderAltered = true;

    this.setState({ topics });
  };

  moveTopic(topic, direction) {
    const { topics } = this.state;
    const index = topics.map((x) => x.gid).indexOf(topic.gid);
    const t = topics[index];
    topics.splice(index, 1);
    topics.splice(index - direction, 0, t);
    this.orderAltered = true;

    this.setState({ topics });
  }

  renderTopic(topic, indicators) {
    if (!indicators.length) {
      return null;
    }

    const checkedIndicators = indicators.filter(this.isChecked.bind(this));
    let selectVisibleEnabled = false;
    const unselectVisibleEnabled = indicators.filter((x) => this.isChecked(x) && x.is_visible).length > 0;

    for (const id in this.state.values) {
      for (const indicator of indicators) {
        if (indicator.id == id && indicator.is_visible) {
          selectVisibleEnabled = true;
        }
      }
    }

    const buttonOptions = {
      selectAll: indicators.length > checkedIndicators.length,
      unselectAll: checkedIndicators.length == indicators.length,
      selectVisible: selectVisibleEnabled,
      unselectVisible: unselectVisibleEnabled
    };

    const setChecked = (ris: GQLReportIndicator[], val: boolean) => {
      const findIndicatorById = (id: string): GQLReportIndicator => {
        return indicators.find((x) => x.id === id);
      };

      const ids = ris.map((x) => x.id);
      const { values } = this.state;
      for (const id of ids) {
        const reportIndicator = findIndicatorById(id);
        const reportIndicatorType = getReportIndicatorType(this.props.report, reportIndicator);
        const checkboxStatus = reportIndicatorType.resolveCheckboxStatus(val, reportIndicator);

        checkboxStatus === CheckboxStatus.noChange ? delete values[id] : values[id] = val;
      }

      this.setState({ values });
    };

    return (
      <TopicContainer key={topic.id} topic={topic} onSortEnd={(params) => this.onSortEnd(params, topic)}>
        <h2>
          {topic.name}
          <span className="sel-buttons">
            {buttonOptions.selectAll && (
              <PrimaryButton onClick={() => setChecked(indicators, true)}>Select all</PrimaryButton>
            )}
            {buttonOptions.unselectAll && (
              <DefaultButton onClick={() => setChecked(indicators, false)}>Select None</DefaultButton>
            )}
            {buttonOptions.selectVisible && (
              <PrimaryButton
                onClick={() =>
                  setChecked(
                    indicators.filter((x) => x.is_visible),
                    true
                  )
                }
              >
                Select Visible
              </PrimaryButton>
            )}
            {buttonOptions.unselectVisible && (
              <DefaultButton
                onClick={() =>
                  setChecked(
                    indicators.filter((x) => x.is_visible),
                    false
                  )
                }
              >
                Deselect Visible
              </DefaultButton>
            )}
          </span>
          <div className="actions-sort">
            {topic.id != this.state.topics[0].id && (
              <FontAwesomeIcon
                icon={"chevron-circle-up"}
                onClick={() => this.moveTopic(topic, 1)}
                data-testid="btn-move-topic-up"
              />
            )}
            {topic.id != this.state.topics[this.state.topics.length - 1].id && (
              <FontAwesomeIcon
                icon={"chevron-circle-down"}
                onClick={() => this.moveTopic(topic, -1)}
                data-testid="btn-move-topic-down"
              />
            )}
          </div>
        </h2>
        <span className="nselected">
          {checkedIndicators.length} out of {indicators.length} selected
        </span>
        {indicators.map((ri, i) => {
          const tierTag = getReportIndicatorType(this.props.report, ri).tier(ri);

          return (
            <TopicElement
              key={i}
              index={i}
              value={
                <>
                  <Checkbox
                    label={ri.indicator.description}
                    onChange={(e, val) => setChecked([ri], val)}
                    checked={this.isChecked(ri)}
                  />
                  {[ri.tier, ...ri.add_on_tags].map((tag) => (
                    <span className="tags" key={tag.id} data-testid="indicator-tier-tag">
                      {tierTag?.name}
                    </span>
                  ))}
                </>
              }
            />
          );
        })}
      </TopicContainer>
    );
  }

  isChecked(ri) {
    if (this.state.values[ri.id] != undefined) {
      return this.state.values[ri.id];
    }

    const reportIndicatorType = getReportIndicatorType(this.props.report, ri);
    return reportIndicatorType.resolveSelectorChecked(ri);
  }

  saveAndClose(mutateFunc) {
    const indicatorValues = this.state.values;
    let sortOrders = [] as any[];
    let successFunc = () => {};
    if (this.orderAltered) {
      sortOrders = this.state.topics.map((x) => [x.id, x.report_indicators!.map((x) => x.id)]);
      successFunc = () => window.location.reload();
      blockPage();
    }

    mutateFunc({ variables: { indicatorValues, sortOrders } }).catch(unblockPage).then(successFunc);
    this.props.onClose();
  }

  closeIfNoChanges() {
    if (Object.keys(this.state.values).length > 0) {
      return;
    }
    this.props.onClose();
  }

  resetSort(mutateFunc) {
    mutateFunc().then((res) => res.data.reset_rt_sort.success && window.location.reload());
  }

  render() {
    const { rt } = this.props;
    const getElementSortOrder = sortOrderBasedOnKeysArray(rt.report_elements_sort_order!);

    const reportElements: IReportElement[] = transformReportElements(rt).sort(
      ({ name: lhsKey }, { name: rhsKey }) => getElementSortOrder(lhsKey) - getElementSortOrder(rhsKey)
    );

    const nindicators = flatten(this.props.rt.topics!.map((x) => x.report_indicators)).length;

    return gqlMutation(this.mutation, (mutateFunc) => (
      <Modal
        title={`Content Configuration - ${rt?.track?.name}`}
        className="content-configuration-modal"
        open
        onClose={() => this.saveAndClose(mutateFunc)}
      >
        <Container>
          <Pivot aria-label="Content Configuration Tabs">
            <PivotItem headerText="Indicators">
              <SelectedReportElements selected={rt.indicators_visible!} total={nindicators}>
                {gqlMutation(this.resetSortMutation, (mutateFunc) => (
                  <DefaultButton onClick={() => this.resetSort(mutateFunc)}>Reset Sort</DefaultButton>
                ))}
              </SelectedReportElements>
              {this.renderIndicators()}
            </PivotItem>
            <PivotItem headerText="Report Elements">
              {gqlMutation(
                this.updateReportElements,
                (update_report_element) => {
                  return gqlMutation(this.defaultResetReportElements, (default_reset_report_element) => {
                    const onChange = (ev?: React.ChangeEvent<HTMLInputElement>, checked?: boolean): void => {
                      const name = ev?.target.name;
                      if (name) {
                        if (this.state.selectedReportElements.has(name)) {
                          this.setSelectedReportElements(
                            new Set<String>(
                              [...this.state.selectedReportElements].filter((reportElement) => reportElement !== name)
                            )
                          );
                        } else {
                          this.setSelectedReportElements(new Set<String>(this.state.selectedReportElements).add(name));
                        }
                        update_report_element({ variables: { id: rt.id, report_element: { [name]: checked } } });
                      }
                    };

                    return (
                      <ReportElementsTab
                        reportElements={reportElements}
                        onChange={onChange}
                        selectedReportElements={this.state.selectedReportElements}
                        showDefaultReset={true}
                        onDefaultReset={() =>
                          default_reset_report_element({ variables: { id: rt.id } }).then(
                            ({ data: { default_reset_report_track_report_elements: rt } }) =>
                              this.setState({
                                selectedReportElements: new Set<String>(
                                  transformReportElements(rt)
                                    .filter((e) => e.selected)
                                    .map((e) => e.name)
                                )
                              })
                          )
                        }
                      />
                    );
                  });
                },
                true
              )}
            </PivotItem>
          </Pivot>
        </Container>
      </Modal>
    ));
  }
}
