import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from "react";
import axios from "axios";
import cn from "classnames";

// Require Editor JS/CSS files.
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/url.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/quick_insert.min.js";
import "froala-editor/js/plugins/inline_style.min.js";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";

import "froala-editor/css/plugins/quick_insert.min.css";

import "froala-editor/js/third_party/font_awesome.min.js";

import { FroalaEditorBase } from "./FroalaEditorBase";
import {
  baseConfigWithModifiers,
  buildGrammarCheckConfig,
  buildImagePluginConfig,
  FroalaConfigModifier,
  FroalaConfigModifiers
} from "./configs";

interface FroalaEditorProps {
  value: string
  onChange: (val: string) => void
  rtc?: boolean
  rtcDocId?: string
  rtcUsername?: string
  imageEnabled?: boolean
  linksEnabled?: boolean
  grammarCheck?: boolean
  objectReference?: string
  versionModel?: any
  versionKey?: string
  name?: string
  autoFocus?: boolean
  characterLimit?: number
  characterLimitAlgo?: (textContent: string, htmlContent: string) => number
  onCharLimitExceeded?: (exceeded: boolean) => void
  placeholder?: string
  noBreak?: boolean
  className?: string
  toolbarTitle?: string
  height?: number
  imageFieldName?: string
  unlimitedHeight?: boolean
  configModifiers?: FroalaConfigModifier[]
}

interface FroalaEditorHandle {
  setValue: (string) => void
  getValue: () => string | undefined
}

export interface IEditor {
  $el: any
  html: {
    set: (string) => void
    get: () => string
  }
  edit: {
    off: () => void
    on: () => void
  }
  events: {
    focus: (boolean) => void
  }
  selection: {
    setAtEnd: (element) => void
    restore: () => void
  }
}

async function getBlobFromUrl(url) {
  const response = await fetch(url);
  const blob = await response.blob();
  return blob;
}

async function uploadToServer($img, objectReference, fieldName, objectParams) {
  const src = $img.attr("src");
  if (src.startsWith("blob")) {
    objectParams.pendingOperations++;
    try {
      const formData = new FormData();
      const imageData = await getBlobFromUrl(src);
      formData.append("file", imageData);
      formData.append("object", objectReference);
      formData.append("field", fieldName);
      const response = await axios.post("/visual_editor_images", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      $img.attr("src", response.data.link);
    } finally {
      objectParams.pendingOperations--;
    }
  }
}

const FroalaEditor: ForwardRefRenderFunction<FroalaEditorHandle, FroalaEditorProps> = (
  {
    value,
    imageEnabled,
    linksEnabled,
    grammarCheck,
    objectReference,
    versionModel,
    versionKey,
    onChange,
    autoFocus,
    characterLimit,
    characterLimitAlgo,
    onCharLimitExceeded,
    placeholder,
    noBreak,
    className,
    toolbarTitle,
    height,
    unlimitedHeight,
    imageFieldName,
    configModifiers = []
  }: FroalaEditorProps, forwardedRef) => {
  const [editor, setEditor] = useState<IEditor>();
  const objectParams = { pendingOperations: 0 };

  useImperativeHandle(forwardedRef, () => ({
    setValue: (value) => {
      editor?.html.set(value);
    },
    getValue: () => {
      return editor?.html.get();
    }
  }));

  const baseConfig = baseConfigWithModifiers(configModifiers);

  const pluginsEnabled = [...baseConfig.pluginsEnabled];
  if (grammarCheck) pluginsEnabled.push("BeyondGrammarPlugin");
  if (characterLimit) pluginsEnabled.push("charCounter");
  if (linksEnabled) pluginsEnabled.push("link");
  if (imageEnabled) pluginsEnabled.push("image");
  pluginsEnabled.push("url");

  const imagePluginConfig = imageEnabled && buildImagePluginConfig(objectReference, imageFieldName);
  const grammarCheckConfig = grammarCheck && buildGrammarCheckConfig();
  const characterLimitConfig = { characterLimit, characterLimitAlgo, onCharLimitExceeded };

  const charCounterConfig = characterLimit && {
    charCounterCount: true
  };

  const baseEvents = {
    initialized: function() {
      // @ts-expect-error
      setEditor(this);

      if (autoFocus) {
        // @ts-expect-error
        this.events.focus(true);
      }
    }
  };

  const events = { ...baseEvents };
  if (imageEnabled) {
    events["image.removed"] = function($img) {
      const src = $img.attr("src");
      if (!src.startsWith("blob")) {
        axios.delete(src)
          .catch((e) => {
            console.error(e);
          });
      }
    };
    events["image.uploaded"] = function(response) {
      console.log("image.uploaded: response", response);
    };
    events["image.loaded"] = function($img) {
      uploadToServer($img, objectReference, imageFieldName, objectParams);
    };
    events.destroy = function() {
      // alert user if there are pending operations
      if (objectParams.pendingOperations > 0) {
        alert("There were pending image uploads. We recommend re-uploading.");
      }
    };
    events["image.error"] = function(error, response) {
      console.log("image.error: error|response", error, response);
    };
  }

  let alteredToolbarButtons = baseConfig.toolbarButtons;
  if (imageEnabled) {
    const toolbarButtons = alteredToolbarButtons;
    alteredToolbarButtons = {
      ...alteredToolbarButtons,
      moreRich: {
        ...toolbarButtons.moreRich,
        buttons: [...toolbarButtons.moreRich.buttons, "insertImage"]
      }
    };
  }

  const inputHeight = unlimitedHeight ? {} : { height: height || 300 };

  const isInModal = configModifiers.includes(FroalaConfigModifiers.inModal);

  const config = {
    ...baseConfig,
    ...imagePluginConfig,
    ...charCounterConfig,
    ...grammarCheckConfig,
    ...inputHeight,
    ...characterLimitConfig,
    toolbarButtons: alteredToolbarButtons,
    multiLine: !noBreak,
    pluginsEnabled,
    placeholderText: placeholder || "",
    events
  };

  return (
    <FroalaEditorBase
      model={value}
      config={config}
      onModelChange={onChange}
      className={cn(className, { "in-modal": isInModal })}
      toolbarTitle={toolbarTitle}
      versionModel={versionModel}
      versionKey={versionKey}
    />
  );
};

export default forwardRef(FroalaEditor);
