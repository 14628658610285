export const Sentry = {
  enabled: import.meta.env.VITE_SENTRY_DSN !== undefined
};

export const Permissions = {
  scoreReports: "score_reports"
};

export const FindingTypes = {
  strength: "Strength",
  risk: "Risk",
  opportunity: "Opportunity"
};

export const FindingRisks = {
  notAvailable: "N/A"
};

export const RecommendationPriorities = {
  low: "low",
  medium: "medium",
  high: "high",
  critical: "critical"
};

export const HighRecommendationPriorities = [RecommendationPriorities.high, RecommendationPriorities.critical];

export const ReportOutputs = {
  mainReport: "main_report",
  preliminaryReport: "preliminary_report",
  supportingAnalysis: "supporting_analysis",
  supportingAnalysisExtended: "supporting_analysis_extended",
  atAGlance: "at_a_glance",
  comprehensive: "comprehensive"
};

export const ReportStatus = {
  new: "new",
  reviewCompleted: "review_completed",
  published: "published"
};

export const ReportRoleNames = {
  project_lead: "Project Lead",
  executive_sponsor: "Executive Sponsor",
  client_recipient: "Client recipient",
  project_management: "Project Management",
  reviewers: "REVIEWERS",
  other_contributors: "Other contributors"
};

export const FileTypes = {
  preliminaryDiligenceReport: "preliminary_diligence_report",
  customSpecialReport: "custom_special_report",
  clientReport: "client_report",
  image: "image",
  reportTypes: ["report_c360", "report_supporting_analysis", "report_at_a_glance", "report_preliminary", "client_report"]
};

export const FileAvailabilities = [
  { key: 0, text: "Visible on Publish" },
  { key: -1, text: "Hidden" },
  { key: 1, text: "Always Visible" }
];

export const RoleNames = {
  client: "client"
};

export const EmphasisTiers = {
  essential: 1,
  additional: 2
};

export const IndicatorVisibility = {
  scored: "SCORED",
  unscored: "UNSCORED"
};

export const CheckboxStatus = {
  noChange: 1,
  change: 2
};
